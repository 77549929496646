import React from "react"

import HeaderClients from "../../components/header_clients"
import RetireesPic from "../../static/images/Retirees.jpg"

export default function Retirees() {
  const heroTitle = "Retirees"

  return (
    <HeaderClients heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <figure class="image-block-wrapper mb-4">
        <img src={RetireesPic} alt={heroTitle} class="img-fluid img-thumbnail" />
      </figure>

      <p>
        Retirement is when you are winding down after a long and fruitful career. We can assist you
        in formulating a strategy that helps you in attaining your desired lifestyle post-retirement
        with peace of mind.
      </p>

      <p>
        During this ‘spending’ phase of retirement, a major concern of clients is ’will I have
        enough to see me through?’ ‘will I leave enough behind for my kids?’. You may be worried
        about the cost of care in old age and wealth preservation to pass on as inheritance. This is
        where we act as your personal financial officer and assist you in planning your retirement
        in a conservative and robust manner. Our key aim is to ensure that you have the finances you
        need to support your retirement goals and objectives.
      </p>
    </HeaderClients>
  )
}
